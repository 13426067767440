<script>
  import { onMount } from 'svelte';
  import { navigate, Router, Link, Route } from 'svelte-routing';
  import model from './model.js';

  import FeaturesList from './components/FeaturesList.svelte';
  import SectionContainer from './components/SectionContainer.svelte';
  import PlayContainer from './components/PlayContainer.svelte';
  import SectionMedia from './components/SectionMedia.svelte';
  import SectionColumn from './components/SectionColumn.svelte';
  import Navigation from './components/Navigation.svelte';
  import NavigationLink from './components/NavigationLink.svelte';

  import { location } from './location.store.js';

  // onMount(_ => {
  //   const path = window.location.pathname.substr(1);
  //   if (!model.routes.map(r => r.name).includes(path)) {
  //     navigate('/surveys');
  //   }
  // });
</script>

<style>:global(html) {
  font-family: IBM Plex Sans, -apple-system, BlinkMacSystemFont, Arial;
  position: relative;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  color: #2d3748;
  background-color: #f7fafc;
  height: 100%;
  /* background: #f7faff; */
}

:global(html, body) {
  overflow: hidden;
  /* @apply flex flex-col; */
}

:global(ul.nav li.active) {
  background-color: #2d3748;
  color: #edf2f7;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9BcHAuc3ZlbHRlIiwiPG5vIHNvdXJjZT4iXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7RUNERixxRUFBQTtFQUFBLG1CQUFBO0VBQUEsNkJBQUE7RUFBQSw4QkFBQTtFQUFBLGVBQUE7RUFBQSwwQkFBQTtFQUFBLGFBQUE7RURJSSx5QkFBeUI7QUFDM0I7O0FBRUE7RUNQRixpQkFBQTtFRFNJLDBCQUEwQjtBQUM1Qjs7QUFFQTtFQ1pGLDBCQUFBO0VBQUEsZUFBQTtBRGNFIiwiZmlsZSI6InNyYy9BcHAuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG4gIDpnbG9iYWwoaHRtbCkge1xuICAgIEBhcHBseSBmb250LXNhbnMgcmVsYXRpdmUgc3VicGl4ZWwtYW50aWFsaWFzZWQgdGV4dC1ncmF5LTgwMCBiZy1ncmF5LTEwMDtcbiAgICBAYXBwbHkgaC1mdWxsO1xuICAgIC8qIGJhY2tncm91bmQ6ICNmN2ZhZmY7ICovXG4gIH1cblxuICA6Z2xvYmFsKGh0bWwsIGJvZHkpIHtcbiAgICBAYXBwbHkgb3ZlcmZsb3ctaGlkZGVuO1xuICAgIC8qIEBhcHBseSBmbGV4IGZsZXgtY29sOyAqL1xuICB9XG5cbiAgOmdsb2JhbCh1bC5uYXYgbGkuYWN0aXZlKSB7XG4gICAgQGFwcGx5IGJnLWdyYXktODAwIHRleHQtZ3JheS0yMDA7XG4gIH1cbiIsbnVsbF19 */</style>

<!-- <iframe
  src="https://jubiwee.daily.co/hello"
  class="z-10"
  style="border: none; position: fixed; top: 0; right: 0; margin-top: -31px;
  width: 320px; height: calc(100vh + 31px) ;" /> -->

<Router>
  <Navigation items={model.routes} let:item let:active>
    <NavigationLink {active}>{item.display_name}</NavigationLink>
  </Navigation>

  <Route path="/">
    <div class="px-6 lg:px-12 py-8">
      <FeaturesList />
    </div>
  </Route>

  <Route path="play">
    <PlayContainer />
  </Route>

  {#each model.routes as route}
    <Route path={route.name}>
      <SectionContainer sections={route.sections} let:section>
        <SectionColumn {section} />
      </SectionContainer>
    </Route>
  {/each}
</Router>
